import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  setPersistence, 
  browserLocalPersistence, 
  browserPopupRedirectResolver,
  initializeAuth
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase with debug logging
if (process.env.NODE_ENV === 'development') {
  console.debug('Firebase Config:', {
    ...firebaseConfig,
    apiKey: '***' // Hide sensitive data
  });
}

const app = initializeApp(firebaseConfig);

// Initialize Auth with custom settings
export const auth = initializeAuth(app, {
  popupRedirectResolver: browserPopupRedirectResolver
});

// Set persistence to LOCAL
setPersistence(auth, browserLocalPersistence)
  .catch((error) => {
    console.error('Error setting auth persistence:', error);
  });

// Initialize Firestore
export const db = getFirestore(app);

// Add global error handler for uncaught Firebase Auth errors
window.addEventListener('unhandledrejection', (event) => {
  if (event.reason?.code?.startsWith('auth/')) {
    console.error('Uncaught Firebase Auth Error:', event.reason);
  }
});

export default app;
