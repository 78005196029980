import React, { useCallback, useMemo } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Tooltip, SelectChangeEvent, Box, useTheme, Theme } from '@mui/material';
import { OutputFormat, OUTPUT_FORMATS } from '../types';

interface FormatSelectorProps {
  selectedFormat: string;
  onFormatChange: (format: string) => void;
  disabled?: boolean;
}

interface FormatMenuItemProps {
  format: OutputFormat;
  theme: Theme;
}

// Memoized MenuItem component
const FormatMenuItem: React.FC<FormatMenuItemProps> = React.memo(({ format, theme }) => (
  <MenuItem 
    value={format.id}
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      minHeight: '48px',
      '&.Mui-selected': {
        backgroundColor: theme.palette.action.selected,
      },
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      }
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
      <span style={{ fontSize: '1.2em' }}>{format.icon}</span>
      <span>{format.name}</span>
    </Box>
  </MenuItem>
));

const FormatSelector: React.FC<FormatSelectorProps> = React.memo(({ selectedFormat, onFormatChange, disabled = false }) => {
  const theme = useTheme();

  // Memoize style objects
  const labelStyles = useMemo(() => ({
    color: theme.palette.text.primary,
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    }
  }), [theme.palette.text.primary, theme.palette.primary.main]);

  const selectStyles = useMemo(() => ({
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    }
  }), [theme.palette.mode, theme.palette.primary.main]);

  // Memoize the current format
  const currentFormat = useMemo(() => 
    OUTPUT_FORMATS.find(f => f.id === selectedFormat),
    [selectedFormat]
  );

  // Memoize the change handler
  const handleChange = useCallback((event: SelectChangeEvent<string>) => {
    onFormatChange(event.target.value);
  }, [onFormatChange]);

  // Memoize the renderValue function
  const renderValue = useCallback((selected: string) => {
    const format = OUTPUT_FORMATS.find(f => f.id === selected);
    if (!format) return <Box sx={{ color: theme.palette.text.secondary }}>Select a format</Box>;
    
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <span style={{ fontSize: '1.2em' }}>{format.icon}</span>
        <span>{format.name}</span>
      </Box>
    );
  }, [theme.palette.text.secondary]);

  return (
    <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
      <InputLabel 
        id="format-select-label" 
        sx={labelStyles}
      >
        Output Format
      </InputLabel>
      <Select
        labelId="format-select-label"
        id="format-select"
        value={selectedFormat}
        onChange={handleChange}
        label="Output Format"
        displayEmpty
        disabled={disabled}
        sx={selectStyles}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 400,
              '& .MuiMenuItem-root': {
                padding: '8px 16px'
              }
            }
          }
        }}
        renderValue={renderValue}
      >
        {OUTPUT_FORMATS.map((format: OutputFormat) => (
          <MenuItem 
            key={format.id} 
            value={format.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              minHeight: '48px',
              '&.Mui-selected': {
                backgroundColor: theme.palette.action.selected,
              },
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              }
            }}
          >
            <Tooltip title={format.description} placement="right">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                <span style={{ fontSize: '1.2em' }}>{format.icon}</span>
                <span>{format.name}</span>
              </Box>
            </Tooltip>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export default FormatSelector;
