import React, { useState } from 'react';
import {
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Snackbar,
  Alert
} from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';

const FeedbackButton: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = async () => {
    try {
      // Using mailto link as a simple solution
      window.location.href = `mailto:tomahawk000@gmail.com?subject=Samanvaya Feedback&body=${encodeURIComponent(feedback)}`;
      setOpen(false);
      setFeedback('');
      setShowSuccess(true);
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };

  return (
    <>
      <Fab
        color="primary"
        aria-label="feedback"
        style={{
          position: 'fixed',
          right: '20px',
          bottom: '20px',
          zIndex: 1000
        }}
        onClick={() => setOpen(true)}
      >
        <FeedbackIcon />
      </Fab>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Send Feedback</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Your Feedback"
            fullWidth
            multiline
            rows={4}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" disabled={!feedback.trim()}>
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          Thank you for your feedback!
        </Alert>
      </Snackbar>
    </>
  );
};

export default FeedbackButton;
