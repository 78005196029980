import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { 
  FormGroup, FormControlLabel, Checkbox, Paper, Typography, Box, 
  CircularProgress, Alert, Collapse, IconButton, Chip, Tooltip,
  styled
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoIcon from '@mui/icons-material/Info';
import { fetchAvailableModels } from '../services/api';
import { Model } from '../types';

interface ModelSelectorProps {
  onModelsSelected: (selectedModels: string[]) => void;
}

const MAX_MODELS = 5;

// Best performing models based on response quality
const RECOMMENDED_MODELS = [
  'anthropic/claude-3.5-sonnet-20240620:beta',
  'google/gemini-pro-1.5',
  'mistralai/mixtral-8x7b-instruct',
  'openai/gpt-4',
  'x-ai/grok-beta'
];

// Styled components
const CategoryHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: theme.palette.action.hover,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  }
}));

const ModelLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5)
}));

interface ModelCategory {
  name: string;
  models: Model[];
  hasRecommended: boolean;
}

// Memoized categorization function
const categorizeModels = (models: Model[]): ModelCategory[] => {
  const categories = new Map<string, Model[]>();
  
  models.forEach(model => {
    const provider = model.id.split('/')[0];
    const existing = categories.get(provider) || [];
    categories.set(provider, [...existing, model]);
  });

  return Array.from(categories.entries())
    .map(([name, categoryModels]) => ({
      name: name.charAt(0).toUpperCase() + name.slice(1),
      models: categoryModels.sort((a, b) => {
        const aRecommended = RECOMMENDED_MODELS.includes(a.id);
        const bRecommended = RECOMMENDED_MODELS.includes(b.id);
        return aRecommended === bRecommended ? a.name.localeCompare(b.name) : bRecommended ? 1 : -1;
      }),
      hasRecommended: categoryModels.some(m => RECOMMENDED_MODELS.includes(m.id))
    }))
    .sort((a, b) => {
      if (a.hasRecommended !== b.hasRecommended) {
        return a.hasRecommended ? -1 : 1;
      }
      return a.name.localeCompare(b.name);
    });
};

// Memoized Category component
const Category = React.memo(({ 
  category, 
  selectedModels, 
  onModelToggle, 
  expanded, 
  onToggle 
}: { 
  category: ModelCategory;
  selectedModels: string[];
  onModelToggle: (modelId: string) => void;
  expanded: boolean;
  onToggle: () => void;
}) => (
  <Box sx={{ mb: 2 }}>
    <CategoryHeader onClick={onToggle}>
      <IconButton size="small" sx={{ mr: 1 }}>
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        {category.name}
      </Typography>
      {category.hasRecommended && (
        <Chip 
          size="small" 
          label="Contains recommended" 
          color="primary"
          variant="outlined"
          sx={{ ml: 1, height: 20 }}
        />
      )}
      <Chip 
        size="small" 
        label={`${category.models.length} ${category.models.length === 1 ? 'model' : 'models'}`}
        sx={{ ml: 'auto' }}
      />
    </CategoryHeader>
    
    <Collapse in={expanded}>
      {category.models.map(model => (
        <FormControlLabel
          key={model.id}
          control={
            <Checkbox
              checked={selectedModels.includes(model.id)}
              onChange={() => onModelToggle(model.id)}
              disabled={!selectedModels.includes(model.id) && selectedModels.length >= MAX_MODELS}
            />
          }
          label={
            <ModelLabel>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="subtitle2">
                  {model.name}
                </Typography>
                {RECOMMENDED_MODELS.includes(model.id) && (
                  <Chip 
                    size="small" 
                    label="Recommended" 
                    color="primary" 
                    variant="outlined"
                    sx={{ height: 20 }}
                  />
                )}
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
                {model.description}
              </Typography>
            </ModelLabel>
          }
          sx={{ 
            ml: 4,
            mb: 1,
            alignItems: 'flex-start',
            '& .MuiFormControlLabel-label': {
              flex: 1
            }
          }}
        />
      ))}
    </Collapse>
  </Box>
));

const ModelSelector: React.FC<ModelSelectorProps> = React.memo(({ onModelsSelected }) => {
  const [models, setModels] = useState<Model[]>([]);
  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
  const [showMaxModelsWarning, setShowMaxModelsWarning] = useState(false);

  useEffect(() => {
    let mounted = true;
    
    const loadModels = async () => {
      try {
        const availableModels = await fetchAvailableModels();
        if (!mounted) return;
        
        setModels(availableModels);
        
        // Pre-select recommended models that are available
        const defaultModels = RECOMMENDED_MODELS
          .filter(modelId => availableModels.some(m => m.id === modelId))
          .slice(0, MAX_MODELS);
        
        setSelectedModels(defaultModels);
        onModelsSelected(defaultModels);

        // Only expand categories with recommended models initially
        const categoriesWithRecommended = defaultModels
          .map(id => id.split('/')[0].charAt(0).toUpperCase() + id.split('/')[0].slice(1));
        setExpandedCategories(Array.from(new Set(categoriesWithRecommended)));
      } catch (err) {
        if (!mounted) return;
        setError('Failed to load models. Please try again later.');
        console.error('Error loading models:', err);
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    loadModels();
    return () => { mounted = false; };
  }, [onModelsSelected]);

  const handleModelToggle = useCallback((modelId: string) => {
    setSelectedModels(prev => {
      if (prev.includes(modelId)) {
        const newSelection = prev.filter(id => id !== modelId);
        onModelsSelected(newSelection);
        return newSelection;
      }
      
      if (prev.length >= MAX_MODELS) {
        setShowMaxModelsWarning(true);
        setTimeout(() => setShowMaxModelsWarning(false), 3000);
        return prev;
      }
      
      const newSelection = [...prev, modelId];
      onModelsSelected(newSelection);
      return newSelection;
    });
  }, [onModelsSelected]);

  const toggleCategory = useCallback((category: string) => {
    setExpandedCategories(prev => 
      prev.includes(category) 
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  }, []);

  const categories = useMemo(() => categorizeModels(models), [models]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Paper sx={{ p: 2, mb: 2, bgcolor: '#fff5f5', color: '#e53e3e' }}>
        {error}
      </Paper>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">
          Available Models
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Tooltip title="Pre-selected models are recommended for best performance">
            <IconButton size="small">
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <Chip 
            label={`${selectedModels.length}/${MAX_MODELS} selected`}
            color={selectedModels.length === MAX_MODELS ? "primary" : "default"}
          />
        </Box>
      </Box>

      <Collapse in={showMaxModelsWarning}>
        <Alert severity="warning" sx={{ mb: 2 }}>
          Maximum {MAX_MODELS} models can be selected
        </Alert>
      </Collapse>

      <FormGroup>
        {categories.map(category => (
          <Category
            key={category.name}
            category={category}
            selectedModels={selectedModels}
            onModelToggle={handleModelToggle}
            expanded={expandedCategories.includes(category.name)}
            onToggle={() => toggleCategory(category.name)}
          />
        ))}
      </FormGroup>
    </Paper>
  );
});

export default ModelSelector;
