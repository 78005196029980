import React, { useState } from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { ModelResponse } from '../types';
import { reformatResponses } from '../services/api';
import FormatSelector from './FormatSelector';
import './ResponseDisplay.css';

interface ReformatCardProps {
  responses: ModelResponse[];
  onReformatted: (newResponse: string) => void;
}

const ReformatCard: React.FC<ReformatCardProps> = ({ responses, onReformatted }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedFormat, setSelectedFormat] = useState('concise');

  const handleReformat = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await reformatResponses(responses, selectedFormat);
      if (result.aggregatedResponse) {
        onReformatted(result.aggregatedResponse);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Error reformatting responses');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper elevation={1} sx={{ mt: 2, p: 2 }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Reformat Response</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <FormatSelector
          selectedFormat={selectedFormat}
          onFormatChange={setSelectedFormat}
          disabled={isLoading}
        />
        <button
          onClick={handleReformat}
          disabled={isLoading}
          className="reformat-button"
        >
          {isLoading ? 'Reformatting...' : 'Reformat'}
        </button>
        {error && <Box className="error-message">{error}</Box>}
      </Box>
    </Paper>
  );
};

export default ReformatCard;
