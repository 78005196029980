export interface ModelResponse {
  model: string;
  response: string | null;
  success: boolean;
  error?: string;
  isFallback?: boolean;
  truncated?: boolean;
  refusal?: string;
}

export interface WebSearchResult {
  title: string;
  snippet: string;
  link: string;
  date?: string;
  position: number;
  type?: string;
}

export interface QuerySummary {
  totalAttempts: number;
  successfulQueries: number;
  failedQueries: number;
  duration: string;
  modelStats: Array<{
    model: string;
    attempts: number;
    successful: number;
    failed: number;
    errors: string[];
  }>;
}

export interface ProcessingStatus {
  stage: string;
  progress: number;
  detail: string;
}

export interface SubmitQueryOptions {
  query: string;
  onStatusUpdate: (status: ProcessingStatus) => void;
  selectedModels: string[];
  outputFormat?: string;
  onPartialUpdate?: (result: ApiResponse) => void;
  reformatOnly?: boolean;
  existingResponses?: ModelResponse[];
}

export interface ApiResponse {
  aggregatedResponse?: string;
  individualResponses?: ModelResponse[];
  webResults?: WebSearchResult[];
  error?: string;
  details?: string;
  status?: ProcessingStatus;
  summary?: QuerySummary;
  partial?: boolean;
}

export interface Model {
  id: string;
  name: string;
  created: number;
  description: string;
  pricing: {
    prompt: string;
    completion: string;
    request: string;
    image: string;
  };
  context_length: number;
  architecture: {
    tokenizer: string;
    instruct_type: string;
    modality: string;
  };
  top_provider: {
    context_length: number;
    max_completion_tokens: number;
    is_moderated: boolean;
  };
  per_request_limits: {
    prompt_tokens: number | null;
    completion_tokens: number | null;
  };
}

export interface ModelsResponse {
  data: Model[];
}

export interface OutputFormat {
  id: string;
  name: string;
  description: string;
  icon: string;
}

export const OUTPUT_FORMATS: OutputFormat[] = [
  {
    id: 'concise',
    name: 'Concise Summary',
    description: 'A brief, to-the-point summary of the key information',
    icon: '📝'
  },
  {
    id: 'detailed',
    name: 'Detailed Article',
    description: 'A comprehensive article with full explanations',
    icon: '📰'
  },
  {
    id: 'bullets',
    name: 'Key Points',
    description: 'Main points in bullet-point format',
    icon: '🔍'
  },
  {
    id: 'eli5',
    name: 'Simple Explanation',
    description: 'Explained like I\'m five years old',
    icon: '👶'
  },
  {
    id: 'tutorial',
    name: 'Step-by-Step Guide',
    description: 'A tutorial-style explanation with clear steps',
    icon: '📚'
  },
  {
    id: 'blog',
    name: 'Blog Post',
    description: 'Written in a casual, blog-style format',
    icon: '✍️'
  },
  {
    id: 'technical',
    name: 'Technical Deep-Dive',
    description: 'Detailed technical explanation with specifics',
    icon: '⚙️'
  },
  {
    id: 'qa',
    name: 'Q&A Format',
    description: 'Structured as questions and answers',
    icon: '❓'
  },
  {
    id: 'analogy',
    name: 'Analogies & Examples',
    description: 'Explained using analogies and real-world examples',
    icon: '🎯'
  },
  {
    id: 'pros-cons',
    name: 'Pros & Cons',
    description: 'Analysis of advantages and disadvantages',
    icon: '⚖️'
  },
  {
    id: 'story',
    name: 'Storytelling',
    description: 'Information presented as an engaging narrative',
    icon: '📖'
  },
  {
    id: 'compare',
    name: 'Comparison',
    description: 'Side-by-side comparison of different aspects',
    icon: '🔄'
  },
  {
    id: 'timeline',
    name: 'Timeline',
    description: 'Information organized chronologically',
    icon: '⏳'
  },
  {
    id: 'mindmap',
    name: 'Mind Map',
    description: 'Hierarchical organization of concepts',
    icon: '🌳'
  },
  {
    id: 'debate',
    name: 'Debate Style',
    description: 'Arguments for and against with analysis',
    icon: '🗣️'
  },
  {
    id: 'research',
    name: 'Research Paper',
    description: 'Academic-style presentation with citations',
    icon: '🔬'
  },
  {
    id: 'howto',
    name: 'How-To Guide',
    description: 'Practical instructions and implementation details',
    icon: '🛠️'
  },
  {
    id: 'summary-bullets',
    name: 'Executive Summary',
    description: 'Brief overview followed by detailed bullet points',
    icon: '📊'
  },
  {
    id: 'eli-expert',
    name: 'Expert Level',
    description: 'Advanced explanation for domain experts',
    icon: '🎓'
  },
  {
    id: 'interactive',
    name: 'Interactive Style',
    description: 'Engaging format with questions and thought experiments',
    icon: '🤝'
  }
];
