import React, { useReducer, useCallback, useMemo, useState, useEffect } from 'react';
import { 
  Box, 
  IconButton, 
  ThemeProvider, 
  createTheme, 
  Paper, 
  Typography, 
  Collapse, 
  Avatar, 
  Menu, 
  MenuItem,
  Alert,
  Snackbar
} from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QueryInput from './components/QueryInput';
import ResponseDisplay from './components/ResponseDisplay';
import LoadingIndicator from './components/LoadingIndicator';
import ModelSelector from './components/ModelSelector';
import LogViewer from './components/LogViewer';
import FormatSelector from './components/FormatSelector';
import HelpDialog from './components/HelpDialog';
import FeedbackButton from './components/FeedbackButton';
import Login from './components/Login';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { submitQuery } from './services/api';
import { saveQuery, updateQueryStatus, saveUserPreferences } from './services/firebase';
import { ProcessingStatus, ApiResponse, ModelResponse } from './types';
import './App.css';

interface Log {
  timestamp: string;
  message: string;
  type: 'info' | 'error' | 'success';
}

interface AppState {
  responses: string[];
  individualResponses: ModelResponse[];
  loading: boolean;
  error: string | null;
  selectedModels: string[];
  logs: Log[];
  outputFormat: string;
  darkMode: boolean;
  modelSelectorExpanded: boolean;
  status: ProcessingStatus;
}

type AppAction =
  | { type: 'SET_INITIAL_RESPONSE'; payload: string | null }
  | { type: 'ADD_REFORMATTED_RESPONSE'; payload: string }
  | { type: 'SET_INDIVIDUAL_RESPONSES'; payload: ModelResponse[] }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: string | null }
  | { type: 'SET_SELECTED_MODELS'; payload: string[] }
  | { type: 'ADD_LOG'; payload: Log }
  | { type: 'SET_OUTPUT_FORMAT'; payload: string }
  | { type: 'TOGGLE_DARK_MODE' }
  | { type: 'TOGGLE_MODEL_SELECTOR' }
  | { type: 'SET_STATUS'; payload: ProcessingStatus }
  | { type: 'CLEAR_LOGS' };

const initialState: AppState = {
  responses: [],
  individualResponses: [],
  loading: false,
  error: null,
  selectedModels: [],
  logs: [],
  outputFormat: 'concise',
  darkMode: false,
  modelSelectorExpanded: false,
  status: {
    stage: 'Ready',
    progress: 0,
    detail: 'Enter your query to begin'
  }
};

function appReducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case 'SET_INITIAL_RESPONSE':
      return { ...state, responses: action.payload ? [action.payload] : [] };
    case 'ADD_REFORMATTED_RESPONSE':
      return { ...state, responses: [...state.responses, action.payload] };
    case 'SET_INDIVIDUAL_RESPONSES':
      return { ...state, individualResponses: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_SELECTED_MODELS':
      return { ...state, selectedModels: action.payload };
    case 'ADD_LOG':
      const newLogs = [...state.logs, action.payload].slice(-100);
      return { ...state, logs: newLogs };
    case 'SET_OUTPUT_FORMAT':
      return { ...state, outputFormat: action.payload };
    case 'TOGGLE_DARK_MODE':
      return { ...state, darkMode: !state.darkMode };
    case 'TOGGLE_MODEL_SELECTOR':
      return { ...state, modelSelectorExpanded: !state.modelSelectorExpanded };
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    case 'CLEAR_LOGS':
      return { ...state, logs: [] };
    default:
      return state;
  }
}

const AppContent: React.FC = () => {
  const { user, signOut } = useAuth();
  const [state, dispatch] = useReducer(appReducer, initialState);
  const [helpOpen, setHelpOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: state.darkMode ? 'dark' : 'light',
        },
      }),
    [state.darkMode],
  );

  const addLog = useCallback((message: string, type: 'info' | 'error' | 'success' = 'info') => {
    const timestamp = new Date().toLocaleTimeString();
    dispatch({ type: 'ADD_LOG', payload: { timestamp, message, type } });
  }, []);

  const handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      handleUserMenuClose();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleSubmit = useCallback(async (query: string) => {
    if (!user) return;
    if (state.selectedModels.length === 0) {
      dispatch({ type: 'SET_ERROR', payload: 'Please select at least one model' });
      addLog('Error: No models selected', 'error');
      return;
    }

    dispatch({ type: 'SET_LOADING', payload: true });
    dispatch({ type: 'SET_ERROR', payload: null });
    dispatch({ type: 'SET_INITIAL_RESPONSE', payload: null });
    dispatch({ type: 'SET_INDIVIDUAL_RESPONSES', payload: [] });
    dispatch({ type: 'CLEAR_LOGS' });
    
    addLog(`Starting query: ${query}`, 'info');

    try {
      const queryId = await saveQuery(query, state.selectedModels, user.uid);

      const result = await submitQuery({
        query,
        onStatusUpdate: async (newStatus: ProcessingStatus) => {
          dispatch({ type: 'SET_STATUS', payload: newStatus });
          addLog(`Status: ${newStatus.stage} - ${newStatus.detail}`, 'info');
          await updateQueryStatus(queryId, newStatus);
        },
        selectedModels: state.selectedModels,
        outputFormat: state.outputFormat,
        onPartialUpdate: async (partialResult: ApiResponse) => {
          if (partialResult.aggregatedResponse) {
            dispatch({ type: 'SET_INITIAL_RESPONSE', payload: partialResult.aggregatedResponse });
          }
          if (partialResult.individualResponses) {
            dispatch({ type: 'SET_INDIVIDUAL_RESPONSES', payload: partialResult.individualResponses });
            await updateQueryStatus(queryId, state.status, partialResult.individualResponses, partialResult.aggregatedResponse);
          }
        }
      });

      if (result.error) {
        addLog(`Error: ${result.error}`, 'error');
        throw new Error(result.error);
      }

      if (result.aggregatedResponse) {
        dispatch({ type: 'SET_INITIAL_RESPONSE', payload: result.aggregatedResponse });
        addLog('Successfully received aggregated response', 'success');
      }
      if (result.individualResponses) {
        dispatch({ type: 'SET_INDIVIDUAL_RESPONSES', payload: result.individualResponses });
        addLog(`Received ${result.individualResponses.length} individual responses`, 'success');
      }
      if (result.summary) {
        addLog(`Query Summary: ${result.summary.successfulQueries} successful, ${result.summary.failedQueries} failed`, 'info');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      dispatch({ type: 'SET_ERROR', payload: errorMessage });
      addLog(`Error: ${errorMessage}`, 'error');
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
      addLog('Query processing completed', 'info');
    }
  }, [state.selectedModels, state.outputFormat, state.status, addLog, user]);

  const handleFormatChange = useCallback((format: string) => {
    dispatch({ type: 'SET_OUTPUT_FORMAT', payload: format });
    addLog(`Changed output format to ${format}`, 'info');
  }, [addLog]);

  const handleReformatted = useCallback((newResponse: string) => {
    dispatch({ type: 'ADD_REFORMATTED_RESPONSE', payload: newResponse });
    addLog('Successfully added reformatted response', 'success');
  }, [addLog]);

  const handleModelsSelected = useCallback((models: string[]) => {
    dispatch({ type: 'SET_SELECTED_MODELS', payload: models });
  }, []);

  const handleCloseError = useCallback(() => {
    dispatch({ type: 'SET_ERROR', payload: null });
  }, []);

  const toggleDarkMode = useCallback(() => {
    dispatch({ type: 'TOGGLE_DARK_MODE' });
  }, []);

  const toggleModelSelector = useCallback(() => {
    dispatch({ type: 'TOGGLE_MODEL_SELECTOR' });
  }, []);

  useEffect(() => {
    if (user) {
      saveUserPreferences(user.uid, {
        selectedModels: state.selectedModels,
        outputFormat: state.outputFormat,
        darkMode: state.darkMode
      }).catch(console.error);
    }
  }, [state.selectedModels, state.outputFormat, state.darkMode, user]);

  if (!user) {
    return <Login />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box className="App" sx={{ 
        bgcolor: 'background.default', 
        color: 'text.primary',
        minHeight: '100vh',
        p: 3
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <img 
              src="/samanavaya-ai-logo.png" 
              alt="Samanvaya Logo" 
              style={{ height: '50px' }}
            />
            <Box>
              <Typography variant="h5" component="h1">Samanvaya</Typography>
              <Typography variant="subtitle1" color="text.secondary">AI Response Aggregation Platform</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <IconButton onClick={() => setHelpOpen(true)} color="inherit" aria-label="help">
              <HelpOutlineIcon />
            </IconButton>
            <IconButton onClick={toggleDarkMode} color="inherit" aria-label="toggle dark mode">
              {state.darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            <IconButton onClick={handleUserMenuClick}>
              <Avatar src={user.photoURL || undefined} alt={user.displayName || 'User'}>
                {user.displayName?.[0] || 'U'}
              </Avatar>
            </IconButton>
          </Box>
        </Box>

        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="body1" paragraph>
            Welcome to Samanvaya, your AI Response Aggregation Platform. Get comprehensive answers by leveraging multiple AI models simultaneously. Select your preferred models below, choose an output format, and start asking questions to receive unified, intelligent responses.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            💡 Tip: Click the help icon above for detailed instructions and features.
          </Typography>
        </Paper>
        
        <Paper sx={{ mb: 3 }}>
          <Box 
            onClick={toggleModelSelector}
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              p: 2, 
              cursor: 'pointer',
              '&:hover': { bgcolor: 'action.hover' }
            }}
          >
            <Typography variant="h6" sx={{ flex: 1 }}>
              Model Selection
            </Typography>
            <Typography color="text.secondary" sx={{ mr: 2 }}>
              {state.selectedModels.length} models selected
            </Typography>
            <IconButton size="small">
              {state.modelSelectorExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          <Collapse in={state.modelSelectorExpanded}>
            <Box sx={{ px: 2, pb: 2 }}>
              <ModelSelector onModelsSelected={handleModelsSelected} />
            </Box>
          </Collapse>
        </Paper>

        <FormatSelector selectedFormat={state.outputFormat} onFormatChange={handleFormatChange} />
        
        <QueryInput onSubmit={handleSubmit} isLoading={state.loading} />
        {state.loading && <LoadingIndicator status={state.status} />}
        
        <LogViewer logs={state.logs} />

        <ResponseDisplay 
          responses={state.responses}
          individualResponses={state.individualResponses}
          onReformatted={handleReformatted}
        />

        <Snackbar 
          open={!!state.error} 
          autoHideDuration={6000} 
          onClose={handleCloseError}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
            {state.error}
          </Alert>
        </Snackbar>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleUserMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem disabled>
            <Typography variant="body2" color="text.secondary">
              {user.email}
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
        </Menu>

        <HelpDialog open={helpOpen} onClose={() => setHelpOpen(false)} />
        <FeedbackButton />
      </Box>
    </ThemeProvider>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
};

export default App;
