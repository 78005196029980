import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  Paper, 
  Typography, 
  TextField, 
  Divider,
  Alert,
  Link,
  alpha
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { useAuth } from '../contexts/AuthContext';

type AuthMode = 'login' | 'register' | 'reset';

const Login = () => {
  const { signInWithGoogle, signInWithEmail, signUp, resetPassword, user } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [mode, setMode] = useState<AuthMode>('login');
  const [loading, setLoading] = useState(false);

  const handleGoogleSignIn = async () => {
    try {
      setError('');
      setLoading(true);
      await signInWithGoogle();
    } catch (error: any) {
      // Display the specific error message from AuthContext
      setError(error.message);
      // Log the error for debugging
      console.error('Google Sign In Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      if (mode === 'register') {
        if (password !== confirmPassword) {
          throw new Error('Passwords do not match');
        }
        await signUp(email, password);
        setSuccess('Registration successful! Please check your email for verification.');
      } else if (mode === 'login') {
        await signInWithEmail(email, password);
      } else if (mode === 'reset') {
        await resetPassword(email);
        setSuccess('Password reset email sent! Please check your inbox.');
      }
    } catch (error: any) {
      setError(error.message || 'Failed to process request');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        p: 3,
        background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
        animation: 'gradientShift 10s ease infinite',
        '@keyframes gradientShift': {
          '0%': {
            backgroundPosition: '0% 50%'
          },
          '50%': {
            backgroundPosition: '100% 50%'
          },
          '100%': {
            backgroundPosition: '0% 50%'
          }
        }
      }}
    >
      <Paper
        elevation={24}
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 400,
          width: '100%',
          borderRadius: 2,
          background: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-5px)'
          }
        }}
      >
        <img 
          src="/samanavaya-ai-logo.png" 
          alt="Samanvaya Logo" 
          style={{ 
            height: '60px', 
            marginBottom: '20px',
            filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))'
          }}
        />
        
        <Box sx={{ mb: 4, textAlign: 'center' }}>
          <Typography 
            variant="body1" 
            sx={{ 
              mb: 2,
              fontSize: '1.1rem',
              lineHeight: 1.6,
              color: 'text.primary'
            }}
          >
            Unlock the power of multiple AI models working together. Samanvaya aggregates responses from various AI models to provide you with comprehensive, balanced, and insightful answers.
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 1,
            background: alpha('#e3f2fd', 0.5),
            p: 2,
            borderRadius: 1
          }}>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              ✨ <span style={{ fontWeight: 500 }}>Get diverse perspectives</span>
            </Typography>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              🎯 <span style={{ fontWeight: 500 }}>Receive more accurate answers</span>
            </Typography>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              🔄 <span style={{ fontWeight: 500 }}>Compare responses across models</span>
            </Typography>
          </Box>
        </Box>

        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              width: '100%', 
              mb: 2,
              borderRadius: 1,
              '& .MuiAlert-message': {
                width: '100%',
                wordBreak: 'break-word'
              }
            }}
          >
            {error}
          </Alert>
        )}

        {success && (
          <Alert 
            severity="success" 
            sx={{ 
              width: '100%', 
              mb: 2,
              borderRadius: 1
            }}
          >
            {success}
          </Alert>
        )}

        {user && !user.emailVerified && mode !== 'reset' && (
          <Alert 
            severity="warning" 
            sx={{ 
              width: '100%', 
              mb: 2,
              borderRadius: 1
            }}
          >
            Please verify your email address. Check your inbox for the verification link.
          </Alert>
        )}

        <form onSubmit={handleEmailAuth} style={{ width: '100%' }}>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
            margin="normal"
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: 'primary.main',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
              },
            }}
          />

          {mode !== 'reset' && (
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'primary.main',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'primary.main',
                  },
                },
              }}
            />
          )}

          {mode === 'register' && (
            <TextField
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              fullWidth
              required
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'primary.main',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'primary.main',
                  },
                },
              }}
            />
          )}

          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
            sx={{
              mt: 3,
              mb: 2,
              py: 1.5,
              fontSize: '1rem',
              fontWeight: 600,
              textTransform: 'none',
              borderRadius: 1,
              background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
              boxShadow: '0 3px 5px 2px rgba(26, 35, 126, .3)',
              transition: 'all 0.3s ease',
              '&:hover': {
                background: 'linear-gradient(45deg, #0d47a1 30%, #1a237e 90%)',
                transform: 'translateY(-2px)',
                boxShadow: '0 6px 10px 2px rgba(26, 35, 126, .3)',
              }
            }}
          >
            {mode === 'login' && 'Sign In'}
            {mode === 'register' && 'Register'}
            {mode === 'reset' && 'Reset Password'}
          </Button>
        </form>

        {mode !== 'reset' && (
          <>
            <Divider sx={{ width: '100%', my: 2 }}>OR</Divider>
            <Button
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
              disabled={loading}
              size="large"
              fullWidth
              sx={{
                py: 1.5,
                fontSize: '1rem',
                textTransform: 'none',
                borderRadius: 1,
                borderWidth: 2,
                position: 'relative',
                '&:hover': {
                  borderWidth: 2,
                  background: 'rgba(66, 133, 244, 0.04)'
                },
                '&:disabled': {
                  borderWidth: 2,
                  opacity: 0.7
                }
              }}
            >
              Sign in with Google
            </Button>
          </>
        )}

        <Box sx={{ mt: 3, textAlign: 'center' }}>
          {mode === 'login' && (
            <>
              <Link
                component="button"
                variant="body2"
                onClick={() => setMode('register')}
                sx={{ 
                  mr: 2,
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                Create an account
              </Link>
              <Link
                component="button"
                variant="body2"
                onClick={() => setMode('reset')}
                sx={{
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                Forgot password?
              </Link>
            </>
          )}
          {(mode === 'register' || mode === 'reset') && (
            <Link
              component="button"
              variant="body2"
              onClick={() => setMode('login')}
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              Back to login
            </Link>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
