import axios from 'axios';
import { auth } from '../config/firebase';
import { ProcessingStatus, ApiResponse, Model } from '../types';

interface QueryParams {
  query: string;
  selectedModels: string[];
  outputFormat: string;
  onStatusUpdate?: (status: ProcessingStatus) => void;
  onPartialUpdate?: (result: ApiResponse) => void;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add auth token to requests
api.interceptors.request.use(async (config) => {
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const fetchAvailableModels = async (): Promise<Model[]> => {
  try {
    const { data } = await api.get('/ai/models');
    return data.data;
  } catch (error) {
    console.error('Error fetching models:', error);
    throw error;
  }
};

export const submitQuery = async ({
  query,
  selectedModels,
  outputFormat,
  onStatusUpdate,
  onPartialUpdate,
}: QueryParams): Promise<ApiResponse> => {
  const eventSource = new EventSource(
    `${process.env.REACT_APP_API_URL}/ai/query?query=${encodeURIComponent(
      query
    )}&models=${encodeURIComponent(JSON.stringify(selectedModels))}&format=${outputFormat}`
  );

  return new Promise((resolve, reject) => {
    let finalResult: ApiResponse = {};

    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        
        if (data.error) {
          finalResult.error = data.error;
          if (onPartialUpdate) {
            onPartialUpdate(finalResult);
          }
          return;
        }

        if (data.status && onStatusUpdate) {
          onStatusUpdate(data.status);
        }

        if (data.aggregatedResponse) {
          finalResult.aggregatedResponse = data.aggregatedResponse;
        }

        if (data.individualResponses) {
          finalResult.individualResponses = data.individualResponses;
        }

        if (onPartialUpdate) {
          onPartialUpdate(finalResult);
        }

        if (data.status?.stage === 'Complete') {
          eventSource.close();
          resolve(finalResult);
        }
      } catch (error) {
        console.error('Error parsing SSE message:', error);
      }
    };

    eventSource.onerror = (error) => {
      console.error('SSE Error:', error);
      eventSource.close();
      reject(new Error('Error processing query'));
    };
  });
};

export const reformatResponses = async (
  responses: any[],
  outputFormat: string
): Promise<ApiResponse> => {
  try {
    const { data } = await api.post('/ai/reformat', {
      responses,
      outputFormat,
    });
    return data;
  } catch (error) {
    console.error('Error reformatting responses:', error);
    throw error;
  }
};
