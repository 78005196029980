import React, { useEffect, useRef, useCallback } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import './LogViewer.css';

export interface Log {
  timestamp: string;
  message: string;
  type: 'info' | 'error' | 'success';
}

interface LogViewerProps {
  logs: Log[];
}

interface LogEntryProps {
  log: Log;
  style: React.CSSProperties;
}

interface AutoSizerProps {
  height: number;
  width: number;
}

const LogEntry: React.FC<LogEntryProps> = React.memo(({ log, style }) => (
  <div className={`log-entry ${log.type}`} style={style}>
    <span className="timestamp">{log.timestamp}</span>
    <span className="message">{log.message}</span>
  </div>
));

const ITEM_SIZE = 24; // Height of each log entry in pixels

const LogViewer: React.FC<LogViewerProps> = React.memo(({ logs }) => {
  const listRef = useRef<List>(null);
  const prevLogsLengthRef = useRef(logs.length);

  // Scroll to bottom when new logs are added
  useEffect(() => {
    if (logs.length > prevLogsLengthRef.current && listRef.current) {
      listRef.current.scrollToItem(logs.length - 1);
    }
    prevLogsLengthRef.current = logs.length;
  }, [logs.length]);

  const renderRow = useCallback(({ index, style }: { index: number; style: React.CSSProperties }) => (
    <LogEntry log={logs[index]} style={style} />
  ), [logs]);

  return (
    <div className="log-viewer">
      <div className="log-header">
        <h3>Execution Logs</h3>
        <span className="log-count">{logs.length} entries</span>
      </div>
      <div className="log-container" style={{ height: '300px' }}>
        <AutoSizer>
          {({ height, width }: AutoSizerProps) => (
            <List
              ref={listRef}
              height={height}
              width={width}
              itemCount={logs.length}
              itemSize={ITEM_SIZE}
              overscanCount={5}
            >
              {renderRow}
            </List>
          )}
        </AutoSizer>
      </div>
    </div>
  );
});

export default LogViewer;
