import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@mui/material';
import {
  CheckCircleOutline,
  FormatAlignLeft,
  Psychology,
  Speed,
  Visibility,
  DarkMode,
  LiveHelp
} from '@mui/icons-material';

interface HelpDialogProps {
  open: boolean;
  onClose: () => void;
}

const HelpDialog: React.FC<HelpDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
    >
      <DialogTitle>
        <Typography variant="h5" component="div">
          Welcome to Samanvaya
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography paragraph>
          Samanvaya (समन्वय), meaning "Integration" or "Synthesis" in Sanskrit, is an AI-powered platform
          that aggregates responses from multiple AI models to provide more comprehensive and accurate answers.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Key Features
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <Psychology />
            </ListItemIcon>
            <ListItemText 
              primary="Multi-Model Integration" 
              secondary="Select multiple AI models to get diverse perspectives on your query"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutline />
            </ListItemIcon>
            <ListItemText 
              primary="Intelligent Aggregation" 
              secondary="Combines responses into a single, coherent answer"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FormatAlignLeft />
            </ListItemIcon>
            <ListItemText 
              primary="Format Selection" 
              secondary="Choose between concise or detailed response formats"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Visibility />
            </ListItemIcon>
            <ListItemText 
              primary="Real-time Progress" 
              secondary="Watch query progress and model responses in real-time"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DarkMode />
            </ListItemIcon>
            <ListItemText 
              primary="Dark/Light Mode" 
              secondary="Toggle between themes for comfortable viewing"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Speed />
            </ListItemIcon>
            <ListItemText 
              primary="Asynchronous Processing" 
              secondary="Queries all selected models simultaneously for faster results"
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom>
          How to Use
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <LiveHelp />
            </ListItemIcon>
            <ListItemText 
              primary="1. Select Models"
              secondary="Click 'Model Selection' to choose which AI models to query"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LiveHelp />
            </ListItemIcon>
            <ListItemText 
              primary="2. Choose Format"
              secondary="Select between concise or detailed response formats"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LiveHelp />
            </ListItemIcon>
            <ListItemText 
              primary="3. Enter Query"
              secondary="Type your question or prompt in the input field"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LiveHelp />
            </ListItemIcon>
            <ListItemText 
              primary="4. View Results"
              secondary="See the aggregated response and individual model responses below"
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default HelpDialog;
