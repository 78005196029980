import { 
  collection,
  addDoc,
  query,
  where,
  orderBy,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  DocumentData
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { ProcessingStatus, ModelResponse } from '../types';

interface QueryData {
  content: string;
  models: string[];
  userId: string;
  timestamp: any;
  status: ProcessingStatus;
  responses: ModelResponse[];
  aggregatedResponse?: string;
}

export const saveQuery = async (
  queryContent: string,
  selectedModels: string[],
  userId: string
): Promise<string> => {
  try {
    const queryData: QueryData = {
      content: queryContent,
      models: selectedModels,
      userId,
      timestamp: serverTimestamp(),
      status: {
        stage: 'Initializing',
        progress: 0,
        detail: 'Starting query processing...'
      },
      responses: []
    };

    const docRef = await addDoc(collection(db, 'queries'), queryData);
    return docRef.id;
  } catch (error) {
    console.error('Error saving query:', error);
    throw error;
  }
};

export const updateQueryStatus = async (
  queryId: string,
  status: ProcessingStatus,
  responses?: ModelResponse[],
  aggregatedResponse?: string
) => {
  try {
    const queryRef = doc(db, 'queries', queryId);
    const updateData: Partial<QueryData> = { status };
    
    if (responses) {
      updateData.responses = responses;
    }
    
    if (aggregatedResponse) {
      updateData.aggregatedResponse = aggregatedResponse;
    }

    await updateDoc(queryRef, updateData);
  } catch (error) {
    console.error('Error updating query status:', error);
    throw error;
  }
};

export const getUserQueries = async (userId: string): Promise<DocumentData[]> => {
  try {
    const q = query(
      collection(db, 'queries'),
      where('userId', '==', userId),
      orderBy('timestamp', 'desc')
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error getting user queries:', error);
    throw error;
  }
};

export const getQueryById = async (queryId: string): Promise<DocumentData | null> => {
  try {
    const queryDoc = await getDoc(doc(db, 'queries', queryId));
    if (queryDoc.exists()) {
      return {
        id: queryDoc.id,
        ...queryDoc.data()
      };
    }
    return null;
  } catch (error) {
    console.error('Error getting query:', error);
    throw error;
  }
};

// User preferences
export const saveUserPreferences = async (
  userId: string,
  preferences: {
    selectedModels?: string[];
    outputFormat?: string;
    darkMode?: boolean;
  }
) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, { preferences });
  } catch (error) {
    console.error('Error saving user preferences:', error);
    throw error;
  }
};

export const getUserPreferences = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return userDoc.data().preferences;
    }
    return null;
  } catch (error) {
    console.error('Error getting user preferences:', error);
    throw error;
  }
};
