import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Paper, Typography, Box, Button, Chip, useTheme } from '@mui/material';
import './ResponseDisplay.css';
import { ModelResponse, QuerySummary } from '../types';
import ReformatCard from './ReformatCard';

interface ResponseDisplayProps {
  responses: string[];
  individualResponses?: ModelResponse[];
  webResults?: string;
  summary?: QuerySummary;
  onReformatted?: (newResponse: string) => void;
}

interface CopyButtonProps {
  text: string;
}

const CopyButton: React.FC<CopyButtonProps> = React.memo(({ text }) => {
  const [copied, setCopied] = React.useState(false);
  const theme = useTheme();

  const handleCopy = React.useCallback(async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  }, [text]);

  return (
    <Button 
      variant="outlined"
      size="small"
      onClick={handleCopy}
      title="Copy to clipboard"
      sx={{
        minWidth: 'auto',
        p: 1,
        color: 'inherit',
        borderColor: 'inherit',
        '&:hover': {
          borderColor: 'inherit',
          opacity: 0.8,
        }
      }}
    >
      {copied ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
          <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
          <path fill="currentColor" d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
        </svg>
      )}
    </Button>
  );
});

interface ResponseSectionProps {
  title: string;
  content: string;
  index?: number;
}

const ResponseSection: React.FC<ResponseSectionProps> = React.memo(({ title, content, index }) => (
  <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      <Typography variant="h6">
        {title} {index !== undefined && index > 0 ? `(Reformatted #${index})` : ''}
      </Typography>
      <CopyButton text={content} />
    </Box>
    <Box className="markdown-content">
      <ReactMarkdown>{content}</ReactMarkdown>
    </Box>
  </Paper>
));

interface ModelResponseItemProps {
  response: ModelResponse;
}

const ModelResponseItem: React.FC<ModelResponseItemProps> = React.memo(({ response }) => (
  <Paper 
    elevation={1} 
    sx={{ 
      p: 2, 
      mb: 2,
      borderColor: response.success ? 'success.main' : 'error.main',
      borderWidth: 1,
      borderStyle: 'solid'
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      <Typography variant="h6">{response.model}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box sx={{ display: 'flex', gap: 0.5 }}>
          {response.isFallback && (
            <Chip 
              label="Fallback" 
              size="small" 
              color="warning"
              variant="outlined"
            />
          )}
          {response.truncated && (
            <Chip 
              label="Truncated" 
              size="small" 
              color="warning"
              variant="outlined"
            />
          )}
          {response.refusal && (
            <Chip 
              label="Refused" 
              size="small" 
              color="error"
              variant="outlined"
            />
          )}
        </Box>
        {response.success && <CopyButton text={response.response || ''} />}
      </Box>
    </Box>
    {response.success ? (
      <>
        <Box className="markdown-content">
          <ReactMarkdown>{response.response || ''}</ReactMarkdown>
        </Box>
        {response.refusal && (
          <Box sx={{ 
            mt: 1, 
            p: 1, 
            borderRadius: 1,
            bgcolor: 'warning.dark',
            color: 'warning.contrastText'
          }}>
            Refusal Note: {response.refusal}
          </Box>
        )}
      </>
    ) : (
      <Box sx={{ 
        p: 1, 
        borderRadius: 1,
        bgcolor: 'error.dark',
        color: 'error.contrastText'
      }}>
        Error: {response.error || 'Unknown error occurred'}
      </Box>
    )}
  </Paper>
));

interface ModelStatsItemProps {
  stat: QuerySummary['modelStats'][0];
}

const ModelStatsItem: React.FC<ModelStatsItemProps> = React.memo(({ stat }) => (
  <Paper elevation={1} sx={{ p: 2, mb: 1 }}>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
      {stat.model}
    </Typography>
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
      <Typography variant="body2">Attempts: {stat.attempts}</Typography>
      <Typography variant="body2">Successful: {stat.successful}</Typography>
      <Typography variant="body2">Failed: {stat.failed}</Typography>
      {stat.errors.length > 0 && (
        <Box sx={{ width: '100%', mt: 1 }}>
          {stat.errors.map((error, i) => (
            <Typography key={i} variant="body2" color="error" sx={{ my: 0.5 }}>
              {error}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  </Paper>
));

const ResponseDisplay: React.FC<ResponseDisplayProps> = React.memo(({ 
  responses = [], 
  individualResponses = [],
  webResults,
  summary,
  onReformatted
}) => {
  const { successfulResponses, failedResponses } = useMemo(() => ({
    successfulResponses: individualResponses.filter(r => r.success),
    failedResponses: individualResponses.filter(r => !r.success)
  }), [individualResponses]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      {webResults && (
        <ResponseSection title="Web Search Results" content={webResults} />
      )}

      {responses.map((response, index) => (
        <ResponseSection 
          key={index}
          title="Synthesized Response"
          content={response}
          index={index}
        />
      ))}

      {individualResponses.length > 0 && onReformatted && (
        <ReformatCard
          responses={individualResponses}
          onReformatted={onReformatted}
        />
      )}

      {individualResponses.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>Model Responses</Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {successfulResponses.length} successful, {failedResponses.length} failed
          </Typography>

          {successfulResponses.length > 0 && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>Successful Responses</Typography>
              {successfulResponses.map((response, index) => (
                <ModelResponseItem key={`success-${index}`} response={response} />
              ))}
            </Box>
          )}

          {failedResponses.length > 0 && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>Failed Responses</Typography>
              {failedResponses.map((response, index) => (
                <ModelResponseItem key={`fail-${index}`} response={response} />
              ))}
            </Box>
          )}
        </Box>
      )}

      {summary && (
        <Paper elevation={1} sx={{ p: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>Query Summary</Typography>
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
            gap: 2,
            mb: 2
          }}>
            <Typography variant="body1">Total Attempts: {summary.totalAttempts}</Typography>
            <Typography variant="body1">Successful Queries: {summary.successfulQueries}</Typography>
            <Typography variant="body1">Failed Queries: {summary.failedQueries}</Typography>
            <Typography variant="body1">Duration: {summary.duration}s</Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Model Statistics</Typography>
            {summary.modelStats.map((stat, index) => (
              <ModelStatsItem key={index} stat={stat} />
            ))}
          </Box>
        </Paper>
      )}
    </Box>
  );
});

export default ResponseDisplay;
