import React, { useState, useCallback, useMemo } from 'react';
import { TextField, Button, Box, Paper, IconButton, Tooltip, CircularProgress, Alert, Collapse } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { submitQuery } from '../services/api';
import { ProcessingStatus } from '../types';

interface QueryInputProps {
  onSubmit: (query: string) => void;
  isLoading: boolean;
}

// Move constants outside component
const QUESTION_PROMPTS = [
  `Generate a fascinating "what if" question that explores an alternate reality or hypothetical scenario. 
   Make it thought-provoking and imaginative, focusing on how this change would affect society, human behavior, or the world.
   Respond with just the question.`,

  `Create a question that connects two seemingly unrelated concepts in a surprising way.
   The connection should make people think "wow, I never considered that relationship before!"
   Respond with just the question.`,

  `Generate a question about the future that challenges our current assumptions about technology, society, or human nature.
   Make it both thought-provoking and slightly unsettling.
   Respond with just the question.`,

  `Create a philosophical question that examines everyday experiences from a completely new angle.
   Help people see the extraordinary in the ordinary.
   Respond with just the question.`,

  `Generate a question that explores the intersection of nature and human innovation.
   Make it spark curiosity about both the natural world and human potential.
   Respond with just the question.`,

  `Create a question that examines how a small change in history or human biology could have massive ripple effects.
   Make it both scientifically interesting and philosophically challenging.
   Respond with just the question.`,

  `Generate a question about consciousness, perception, or reality that makes people question their assumptions.
   Focus on the mysteries of human experience and understanding.
   Respond with just the question.`,

  `Create a question that explores how emerging technologies might reshape fundamental human experiences.
   Consider both the exciting possibilities and potential ethical implications.
   Respond with just the question.`
];

// Memoized form component
const QueryForm: React.FC<{
  query: string;
  setQuery: (query: string) => void;
  isLoading: boolean;
  isGenerating: boolean;
  onGenerate: () => void;
  onSubmit: (e: React.FormEvent) => void;
}> = React.memo(({ query, setQuery, isLoading, isGenerating, onGenerate, onSubmit }) => (
  <form onSubmit={onSubmit}>
    <Box sx={{ display: 'flex', gap: 2, position: 'relative' }}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Enter your question..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        disabled={isLoading}
        multiline
        rows={3}
        sx={{ 
          flex: 1,
          '& .MuiOutlinedInput-root': {
            pr: '48px'
          }
        }}
      />
      <Box sx={{ 
        position: 'absolute', 
        right: '120px',
        top: '8px',
        zIndex: 1 
      }}>
        <Tooltip title="Generate an interesting question">
          <IconButton
            onClick={onGenerate}
            disabled={isGenerating || isLoading}
            sx={{
              animation: isGenerating ? 'spin 1s linear infinite' : 'none',
              '@keyframes spin': {
                '0%': {
                  transform: 'rotate(0deg)',
                },
                '100%': {
                  transform: 'rotate(360deg)',
                },
              },
            }}
          >
            {isGenerating ? (
              <CircularProgress size={24} />
            ) : (
              <SmartToyIcon color={isGenerating ? 'primary' : 'action'} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={!query.trim() || isLoading}
        endIcon={<SendIcon />}
        sx={{ alignSelf: 'flex-start' }}
      >
        Send
      </Button>
    </Box>
  </form>
));

const QueryInput: React.FC<QueryInputProps> = React.memo(({ onSubmit, isLoading }) => {
  const [query, setQuery] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Memoize the random prompt selection
  const getRandomPrompt = useCallback(() => {
    return QUESTION_PROMPTS[Math.floor(Math.random() * QUESTION_PROMPTS.length)];
  }, []);

  const generateSampleQuery = useCallback(async () => {
    if (isGenerating || isLoading) return;

    setIsGenerating(true);
    setError(null);

    try {
      const prompt = getRandomPrompt();
      
      const result = await submitQuery({
        query: prompt,
        onStatusUpdate: (_: ProcessingStatus) => {}, // Empty status update handler
        selectedModels: ['anthropic/claude-3.5-sonnet-20240620:beta'],
        outputFormat: 'concise'
      });

      if (result.error) {
        throw new Error(result.error);
      }

      if (result.aggregatedResponse) {
        const cleanedResponse = result.aggregatedResponse
          .replace(/^["']|["']$/g, '')
          .replace(/\n/g, ' ')
          .trim();
        setQuery(cleanedResponse);
      } else {
        throw new Error('No response generated');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to generate question';
      setError(errorMessage);
      console.error('Error generating sample query:', error);
    } finally {
      setIsGenerating(false);
    }
  }, [isGenerating, isLoading, getRandomPrompt]);

  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    if (query.trim()) {
      onSubmit(query.trim());
    }
  }, [query, onSubmit]);

  // Memoize the error display timeout
  useMemo(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
      <Collapse in={!!error}>
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      </Collapse>
      
      <QueryForm
        query={query}
        setQuery={setQuery}
        isLoading={isLoading}
        isGenerating={isGenerating}
        onGenerate={generateSampleQuery}
        onSubmit={handleSubmit}
      />
    </Paper>
  );
});

export default QueryInput;
