import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  User,
  GoogleAuthProvider,
  signInWithPopup,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  AuthError,
  PopupRedirectResolver
} from 'firebase/auth';
import { auth } from '../config/firebase';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signInWithGoogle: () => Promise<void>;
  signInWithEmail: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  sendVerificationEmail: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.debug('Auth state changed:', user ? 'User logged in' : 'User logged out');
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const signInWithGoogle = async () => {
    try {
      console.debug('Starting Google sign-in process...');
      const provider = new GoogleAuthProvider();
      
      // Add scopes for better user experience
      provider.addScope('profile');
      provider.addScope('email');
      
      // Set custom parameters
      provider.setCustomParameters({
        prompt: 'select_account',
        display: 'popup'
      });

      console.debug('Initiating popup...');
      const result = await signInWithPopup(auth, provider);
      
      if (!result.user) {
        throw new Error('No user data returned from Google sign in');
      }
      
      console.debug('Successfully signed in with Google:', {
        uid: result.user.uid,
        email: result.user.email,
        displayName: result.user.displayName
      });
      
    } catch (error) {
      const authError = error as AuthError;
      console.error('Detailed Google sign-in error:', {
        code: authError.code,
        message: authError.message,
        name: authError.name,
        stack: authError.stack,
        customData: authError.customData
      });

      // Handle specific error cases
      if (authError.code === 'auth/popup-blocked') {
        throw new Error('The sign-in popup was blocked by your browser. Please allow popups for this site and try again.');
      } else if (authError.code === 'auth/popup-closed-by-user') {
        throw new Error('The sign-in popup was closed before completing the sign-in process. Please try again.');
      } else if (authError.code === 'auth/cancelled-popup-request') {
        throw new Error('Another sign-in popup is already open. Please close it and try again.');
      } else if (authError.code === 'auth/network-request-failed') {
        throw new Error('Network error occurred. Please check your internet connection and try again.');
      } else {
        throw new Error(`Failed to sign in with Google: ${authError.message}`);
      }
    }
  };

  const signInWithEmail = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error('Error signing in with email:', error);
      throw error;
    }
  };

  const signUp = async (email: string, password: string) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(result.user);
    } catch (error) {
      console.error('Error signing up:', error);
      throw error;
    }
  };

  const sendVerificationEmail = async () => {
    try {
      if (user) {
        await sendEmailVerification(user);
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
      throw error;
    }
  };

  const resetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error('Error resetting password:', error);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  };

  const value = {
    user,
    loading,
    signInWithGoogle,
    signInWithEmail,
    signUp,
    sendVerificationEmail,
    resetPassword,
    signOut
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
