import React, { useMemo } from 'react';
import { Box, LinearProgress, Typography, Paper, Chip, Theme, useTheme } from '@mui/material';
import { ProcessingStatus } from '../types';

interface LoadingIndicatorProps {
  status: ProcessingStatus;
}

// Helper functions moved outside component
const getStatusColor = (stage: string): string => {
  switch (stage) {
    case 'Initializing':
      return '#e3f2fd';
    case 'Collecting Responses':
      return '#fff3e0';
    case 'Synthesizing':
    case 'Complete':
      return '#e8f5e9';
    default:
      return '#f3e5f5';
  }
};

const getModelName = (detail: string): string => {
  const match = detail.match(/Querying (.*)\.\.\./);
  return match ? match[1] : '';
};

interface StatusHeaderProps {
  stage: string;
  progress: number;
  theme: Theme;
}

// Memoized status header component
const StatusHeader = React.memo(({ stage, progress, theme }: StatusHeaderProps) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
      {stage}
    </Typography>
    <Chip 
      label={`${Math.round(progress)}%`}
      color="primary"
      variant="outlined"
      size="small"
    />
  </Box>
));

interface StatusDetailProps {
  detail: string;
  stage: string;
}

// Memoized status detail component
const StatusDetail = React.memo(({ detail, stage }: StatusDetailProps) => {
  const modelName = useMemo(() => 
    stage === 'Collecting Responses' ? getModelName(detail) : '',
    [stage, detail]
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography variant="body1" color="text.secondary">
        {detail}
      </Typography>
      {modelName && (
        <Chip 
          label={modelName}
          size="small"
          sx={{ 
            backgroundColor: '#bbdefb',
            '& .MuiChip-label': {
              fontWeight: 500
            }
          }}
        />
      )}
    </Box>
  );
});

const LoadingIndicator: React.FC<LoadingIndicatorProps> = React.memo(({ status }) => {
  const theme = useTheme();

  // Memoize style objects
  const progressStyles = useMemo(() => ({ 
    height: 8, 
    borderRadius: 5,
    backgroundColor: '#e0e0e0',
    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 5,
      transition: 'transform 0.3s ease'
    }
  }), [theme.palette.primary.main]);

  const containerStyles = useMemo(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  }), []);

  // Memoize background color
  const backgroundColor = useMemo(() => 
    getStatusColor(status.stage),
    [status.stage]
  );

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        p: 3, 
        my: 2, 
        backgroundColor 
      }}
    >
      <Box sx={containerStyles}>
        <StatusHeader 
          stage={status.stage}
          progress={status.progress}
          theme={theme}
        />
        
        <LinearProgress 
          variant="determinate" 
          value={status.progress} 
          sx={progressStyles}
        />
        
        <StatusDetail 
          detail={status.detail}
          stage={status.stage}
        />
      </Box>
    </Paper>
  );
});

export default LoadingIndicator;
